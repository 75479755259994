import { $post, $get, $getCode } from '@/utils/request'

// 文件服务器地址
export const fileUrl = process.env.NODE_ENV == 'development' ? '' : process.env.VUE_APP_BASE_API //http://192.168.4.23:8059/prod-api

// 发送手机验证码(注册)
export const sendRegPhoneCode = params => $post('/user/sendRegPhoneCode', params)

// 发送手机验证码
export const sendPhoneCode = params => $post('/user/sendPhoneCode', params)

// 校验手机号或者邮箱是否已注册
export const checkPhoneOrEmai = params => $post('/user/checkPhoneOrEmail', params)

// 验证码登录
export const login = params => $post('/login/code', params)

// 登出
export const logout = params => $post('/logout', params)

// 获取五大宗教tab
export const getReligionMenu = params => $get('/index/getFiveReligious', params)

// 获取轮播图
export const getBannerList = params => $get('/favorite/getLunbotu', params)

// 获取动态资讯
export const getInformation = params => $get('/index/getInformation', params)

// 获取政策法规
export const getRegulations = params => $get('/index/getRegulations', params)

// 获取关键词
export const getKeywords = params => $get('/thesis/getKeyword', params)

// 根据年份关键词查询下载量前10论文
export const getThesisByYearAndKeyword = params => $get('/thesis/getThesisByYearAndKeyword', params)

// 根据上架时间/下载量正序/查看量 获取论文
export const getThesisByListingTime = params => $get('/thesis/getThesisByListingTime', params)

// 根据宗教获取宗教下相关院校
export const getAcademy = params => $get('/index/getAcademy', params)

// 收录地图
export const getThesisCountByYearAndgradation = params => $get('/thesis/getThesisCountByYearAndgradation', params)

// 收录地图，根据年份获取下载量前十的论文
export const getThesisByYear = params => $get('/thesis/getThesisByYearAndgradation', params)

// 修改用户信息
export const updateUserMessage = params => $post('/user/updateMessage', params, 'application/json')

// 获取当日热词前10
export const getSearchHotWord = params => $get('/thesis/getSearchHotWord', params)

// 获取当日下载量前10
export const getDailyDownloads = params => $get('/thesis/getDailyDownloads', params)

// 获取院校相关作者
export const getAuthorByAcademy = params => $get('/index/getAuthorByAcademy', params)

// 获取院校相关领域
export const getFieldByAcademy = params => $get('/index/getFieldByAcademy', params)

// 获取院校详情
export const getInformationByAcademyId = params => $get('/index/getInformationByAcademyId', params)

// 院校详情最高被引
export const getMostQuoteByAcademyId = params => $get('/index/getMostQuoteByAcademyId', params)

// 院校详情最高下载 
export const getDownloadsByReligiousType = params => $get('/index/getDownloadsByReligiousType', params)

// 论文详情
export const getLwthesisDetails = params => $get('/thesis/getLwthesisDetails', params)

// 论文详情关键词
export const getLwthesisKeyword = params => $get('/thesis/getLwthesisKeyword', params)

// 论文被引用次数
export const getLwthesisQuote = params => $get('/thesis/getLwthesisQuote', params)

// 论文详情页相关推荐
// export const getTuijian = params => $get('/thesis/getTuijian', params)

// 获取论文作者
// export const getAuthor = params => $get('/thesis/getAuthor', params)

// 收藏论文
export const collectThesis = params => $get('/user/collectThesis', params)

// 取消收藏论文
export const cancelCollectThesis = params => $get('/user/cancelCollectThesis', params)

// 注销账号
export const userCancel = params => $get('/user/cancel', params)

// 创建订单
export const addOrder = params => $post('/order/addOrder', params, 'application/json')

// 取消订单
export const cancelOrder = params => $get('/order/cancelPricing', params, 'application/json')

// 获取热搜词
export const getRendingSearch = params => $get('/index/getRendingSearch', params)

// 高级搜索
export const getSearch = params => $post('/thesis/getSearch', params, 'application/json')

// 我的收藏
export const getMyCollect = params => $get('/user/getMyCollect', params)

// 研究领域
export const getField = params => $get('/thesis/getField', params)

// 为您推荐
export const getRecommend = params => $post('thesis/getRecommend', params, 'application/json')

// 查询订单状态
export const checkOrderIsSuccess = (params, options) => $post('/pay/checkOrderIsSuccess', params, undefined, options)

// 我的订单
export const selOrderByUserId = params => $get('/order/selOrderByUserId', params)

// 论文下载 
export const thesisdownload = params => $get('/download/downloadFTP', params, 'text')

// 查询当前用户某论文是否已购买
export const downloadableOrNot = params => $get('/order/downloadableOrNot', params)

// 热门发现
export const selHotDiscovery = params => $get('/order/selHotDiscovery', params)

// 更新浏览次数
export const updateLookTotal = params => $post('/index/updateLookTotal', params)

// 获取专题列表
export const getPopUpAdvertisement = params => $get('/popUpAdvertisement/getPopUpAdvertisement', params)

// 从支付宝支付页面跳转回项目，根据订单号获取论文id，然后进入到论文详情页
export const getOrderDetailByOrderNum = params => $get('index/getOrderDetailByOrderNum', params)



