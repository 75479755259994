import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login/index.vue')
  },
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home/index.vue')
  },
  {
    path: '/religion-detail',
    name: 'religionDetail',
    component: () => import('../views/ReligionDetail/index.vue')
  },
  {
    path: '/search',
    name: 'search',
    meta: {
      keepAlive: true
    },
    component: () => import('../views/Search/index.vue')
  },
  {
    path: '/search-result',
    name: 'searchResult',
    component: () => import('../views/SearchResult/index.vue')
  },
  {
    path: '/organ-list',
    name: 'organList',
    component: () => import('../views/OrganList/index.vue')
  },
  {
    path: '/organ-detail',
    name: 'organDetail',
    component: () => import('../views/OrganDetail/index.vue')
  },
  {
    path: '/paper-list',
    name: 'paperList',
    component: () => import('../views/PaperList/index.vue')
  },
  {
    path: '/paper-detail',
    name: 'paperDetail',
    component: () => import('../views/PaperDetail/index.vue')
  },
  {
    path: '/article-list',
    name: 'articleList',
    component: () => import('../views/ArticleList/index.vue')
  },
  {
    path: '/article-detail',
    name: 'articleDetail',
    component: () => import('../views/ArticleDetail/index.vue')
  },
  {
    path: '/personal-center',
    name: 'personalCenter',
    component: () => import('../views/PersonalCenter/index.vue'),
    children: [
      {
        path: 'account-information', // 账户信息
        name: 'accountInformation',
        component: () => import('../views/PersonalCenter/accountInformation.vue')
      },
      {
        path: 'my-collect', // 我的收藏
        name: 'myCollect',
        component: () => import('../views/PersonalCenter/myCollect.vue')
      },
      {
        path: 'my-order', // 我的订单
        name: 'myOrder',
        component: () => import('../views/PersonalCenter/myOrder.vue')
      },
      {
        path: 'recommend-article', // 为您推荐
        name: 'recommendArticle',
        component: () => import('../views/PersonalCenter/recommendArticle.vue')
      },
      {
        path: 'hot-article', // 热门发现
        name: 'hotArticle',
        component: () => import('../views/PersonalCenter/hotArticle.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
