import JSEncrypt from 'jsencrypt'

export const textCopy = (t) => {
  // 如果当前浏览器版本不兼容navigator.clipboard
  if (!navigator.clipboard) {
    let ele = document.createElement("input");
    ele.value = t;
    document.body.appendChild(ele);
    ele.select();
    document.execCommand("copy");
    document.body.removeChild(ele);
    if (document.execCommand("copy")) {
      console.log("复制成功！");
    } else {
      console.log("复制失败！");
    }
  } else {
    navigator.clipboard.writeText(t).then(function () {
      console.log("复制成功！");
    }).catch(function () {
      console.log("复制失败！");
    })
  }
}

export function getUrlKey (name) {
  return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null
}

export function extractString(str) {
  const temp = str.split("-----BEGIN PUBLIC KEY-----");
  const temp2 = temp[1].split("-----END PUBLIC KEY-----");
  if (temp.length > 1 && temp2.length > 0) {
      return temp2[0];
  } else {
      return null;
  }
}
 
// 去除空格 去除换行符
export const strWithoutSpaces = (str) => {
  str.replace(/\s/g, "").replace(/\n/g, "");
  return str
}

// 使用公匙进行数据加密码
export function encryptData(data, publicKey) {
  let encrypted;

  let rsaEncrypt = new JSEncrypt();
  rsaEncrypt.setPublicKey(publicKey);

  encrypted = rsaEncrypt.encrypt(data);   

  return encrypted;
}

// 使用私匙进行数据解密码
export function decryptData(encrypted, privateKey) {
  let uncrypted;

  let rsadecrypt = new JSEncrypt(); 
  rsadecrypt.setPrivateKey(privateKey);

  uncrypted = rsadecrypt.decrypt(encrypted);  

  return uncrypted;
}
