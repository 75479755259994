<template>
  <div id="app">
    <Header v-if="$route.name !== 'login'"></Header>
    <div class="content">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"/>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"/>
    </div>
    <div class="footer" :class="$route.name != 'login' ? 'bg-grey' : 'bg-white'">
      <p>京ICP备18013502号 Copyright ©宗教文化出版社有限公司 版权所有</p>
      <p>地址：北京市西城区后海北沿44号    邮编：100009</p>
    </div>
  </div>
</template>
<script>
  // import Header from '@/components/Header'
  export default {
    components: {
      'Header': () => import('@/components/Header'),
    }
  }
</script>
<style lang="scss" scoped>
  #app {
    background-color: #eceef3;
  }
  .content {
    min-height: calc(100vh - 40px)
  }
  .footer {
    text-align: center;
    line-height: 40px;
    font-weight: 400;
    color: #B6B7B9;
  }
  .bg-grey {
    background-color: #eceef3;
  }
  .bg-white {
    background-color: #fff;
  }
</style>
