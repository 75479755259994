import router from './router'
import store from './store'
import Vue from 'vue'

const whiteList = ['/', '/login', '/religion-detail'] // 跳转白名单 已经是登录页了就不需要再跳了

router.beforeEach((to, from, next) => {
  if (store.state.token) {
    if (to.path === '/login') {
      next('/')
    } else {
      // 有token 该干嘛干嘛
      next()
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      Vue.prototype.$confirm('您需要先登录才能继续此操作，是否去登录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info',
        lockScroll: false
      }).then(() => {
        // 未登录状态，访问论文详情页，记录fullPath, 登录后直接跳转到该详情页
        // 主要是为了实现个人中心分享功能
        if(to.name == 'paperDetail') {
          sessionStorage.setItem('SHARE_PATH', to.fullPath)
        }
        next('/login')
        store.commit('UPDATE_MENUINDEX', 0)
      }).catch(() => {})
      // next()
    }
  }
})