import Vue from 'vue';
import {
  Input,
  Carousel,
  CarouselItem,
  Icon,
  Breadcrumb,
  BreadcrumbItem,
  Avatar,
  Select,
  Option,
  Button,
  Checkbox,
  Table,
  TableColumn,
  Pagination,
  Collapse,
  CollapseItem,
  Statistic,
  RadioButton,
  RadioGroup,
  Form,
  FormItem,
  Upload,
  Radio,
  CheckboxButton,
  CheckboxGroup,
  Message,
  Badge,
  Dialog,
  Loading,
  MessageBox
} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(Input);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Icon);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Avatar);
Vue.use(Select);
Vue.use(Option);
Vue.use(Button);
Vue.use(Checkbox);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Statistic);
Vue.use(RadioButton);
Vue.use(RadioGroup);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Upload);
Vue.use(Radio);
Vue.use(CheckboxButton);
Vue.use(CheckboxGroup);
Vue.use(Badge);
Vue.use(Dialog);
Vue.use(Loading);

Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$alert = MessageBox.alert