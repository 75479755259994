import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'
import JSEncrypt from 'jsencrypt'
import { 
  collectThesis, 
  cancelCollectThesis, 
  updateLookTotal, 
  addOrder, 
  downloadableOrNot,
  thesisdownload
 } from '@/api/urls'
import { getItem, setItem, removeAllItem } from '@/utils/storage'
import { extractString, decryptData, strWithoutSpaces } from '@/utils/utils.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: getItem('TOKEN') || '',
    userInfo: getItem('USERINFO') || {},
    menuIndex: sessionStorage.getItem('menuIndex') || 0,
    collectCount: getItem('COLLECTCOUNT') || 0,
    orderCount: getItem('ORDERCOUNT') || 0,
  },
  getters: {
    token: state =>  state.token
  },
  mutations: {
    UPDATE_MENUINDEX(state, payload) {
      sessionStorage.setItem('menuIndex', payload)
      state.menuIndex = payload
    },
    SAVE_TOKEN(state, payload) {
      state.token = payload
      setItem('TOKEN', payload)
    },
    SAVE_USERINFO(state, payload) {
      state.userInfo = payload
      setItem('USERINFO', JSON.stringify(payload))
    },
    UPDATE_COLLECT_COUNT(state, payload) {
      state.collectCount++
      setItem('COLLECTCOUNT', state.collectCount)
    },
    CLEAR_COLLECT_COUNT(state, payload) {
      state.collectCount = 0
      setItem('COLLECTCOUNT', state.collectCount)
    },
    UPDATE_ORDER_COUNT(state, payload) {
      state.orderCount++
      setItem('ORDERCOUNT', state.orderCount)
    },
    CLEAR_ORDER_COUNT(state, payload) {
      state.orderCount = 0
      setItem('ORDERCOUNT', state.orderCount)
    }
  },
  actions: {
    logout(context) {
      router.push('/')
      context.commit('SAVE_TOKEN', '')
      context.commit('SAVE_USERINFO', {})
      context.commit('UPDATE_MENUINDEX', 0)
      removeAllItem()
    },
    collectPaper(context, id) {
      return new Promise((resolve, reject) => {
        collectThesis({thesisId: id})
          .then(res => {
            Vue.prototype.$message.success('已收藏')
            context.commit('UPDATE_COLLECT_COUNT', 1)
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    cancelCollectPaper(context, id) {
      return new Promise((resolve, reject) => {
        cancelCollectThesis({thesisId: id})
          .then(res => {
            Vue.prototype.$message.success('已取消收藏')
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    updateLookTotal(context, params) {
      // 只有登录的用户才计算浏览量
      if(context.state.token) {
        return new Promise((resolve, reject) => {
          updateLookTotal(params)
            .then(res => {
              resolve()
            })
            .catch(err => {
              reject(err)
            })
        })
      }
    },
    addOrder(context, params) {
      return new Promise((resolve, reject) => {
        addOrder({userId: context.state.userInfo.userId, thesisId: params.paperId})
        .then(res => {
          const price = params.paperObj.price ? parseFloat(params.paperObj.price) : 0
          // 价格为0则不跳转支付页面，直接下载
          if(price > 0) {
            const aliForm = res.qr_code || res.aliQrcodeUrl
            if (aliForm) {
              const div = document.createElement('divform')
              div.innerHTML = aliForm
              document.body.appendChild(div)
              document.forms[0].submit();
              document.body.removeChild(div)
            }
          }else {
            context.dispatch('thesisdownload', {paperName: params.paperObj.thesisname, paperId: params.paperId})
          }
          // addFlag: 0 新订单，1 老订单
          if(res.addFlag == 0) {
            context.commit('UPDATE_ORDER_COUNT')
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    getPayStatus(context, params) {
      return new Promise((resolve, reject) => {
        downloadableOrNot({thesisId: params.paperId})
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            console.log(err)
            reject(err)
          })
      })
    },
    thesisdownload(context, params) {
      return new Promise((resolve, reject) => {
        // 生成秘钥对
        const encrypt = new JSEncrypt()
        const privateKey = encrypt.getPrivateKey()
        let publicKey = encrypt.getPublicKey()
        publicKey = extractString(publicKey).trim()
        thesisdownload({thesisId: params.paperId, publicKeyString: publicKey})
          .then(res => {
            let url = decryptData(res, privateKey)
            //导出文件
            // 通过a标签打开新页面下载文件
            const a = document.createElement('a')
            // 构造一个blob对象来处理数据
            // const blob = res.data
            // if (res.headers['content-disposition']) {
            //   // 拿到用户上传的文件名
            //   let fileName = params.paperName + '.pdf' // res.headers['content-disposition'].split('filename=')[1].split('\"')[1]
            //   fileName = decodeURI(encodeURI(fileName))
            //   // URL.createObjectURL()会产生一个url字符串，可以像使用普通 URL 那样使用它，比如用在 img.src 上
            //   a.href = URL.createObjectURL(blob)
            //   // a标签里有download属性可以自定义文件名
            //   a.setAttribute('download', fileName)
            //   document.body.appendChild(a)
            //   a.click();
            //   document.body.removeChild(a)
            //   Vue.prototype.$message.success('下载成功')
            // }

            let fileName = params.paperName + '.pdf'
            a.href = decryptData(res, privateKey)
            a.setAttribute('download', fileName)
            document.body.appendChild(a)
            a.click();
            document.body.removeChild(a)
            // Vue.prototype.$message.success('下载成功')
          })
          .catch(err => {
            // Vue.prototype.$message.error('下载失败')
          })
      })
    }
  },
  modules: {
  }
})
