import axios from 'axios'
import store from '@/store'
import Vue from 'vue'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 60000
})

// 请求拦截器
service.interceptors.request.use(
  config => {
    // 在这个位置需要统一的去注入token
    if (store.getters.token) {
      // 如果token存在 注入token
      config.headers.Authorization = `Bearer ${store.getters.token}`
    }
    return config // 必须返回配置
  },
  error => {
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => {
    if(response.config.responseType == 'blob') {
      return response
    }else {
      let data;
      try {
        data = JSON.parse(response.data)
      } catch (error) {
        data = response.data
      }
      const { code, msg } = data
      //   要根据success的成功与否决定下面的操作
      if (code == 200) {
        return data.data
      } else if (code == 401) {
        // Vue.prototype.$confirm('您需要先登录才能继续此操作，是否去登录?', '提示', {
        //   confirmButtonText: '确定',
        //   cancelButtonText: '取消',
        //   type: 'info'
        // }).then(() => {
        //   store.dispatch('logout')
        // }).catch(() => {})
        Vue.prototype.$message.warning('用户信息已失效，请重新登录')
        store.dispatch('logout')
        return Promise.reject(new Error(msg))
      }else if (code == 402) {
        store.dispatch('logout')
        Vue.prototype.$message.warning('用户信息已失效，请重新登录')
        return Promise.reject(msg)
      }else if (code == 405) {
        return Promise.reject(msg)
      }
    }
  },
  error => {
    return Promise.reject(error)
  }
)

//get
export const $get = (url, params = {}, responseType = "json") => {
  return service.get(url, {
    params: params,
    responseType: responseType
  })
}

// 获取blob
export const $getCode = (url, params = {}) => {
  return service({
    method: "get",
    url,
    params: params,
    responseType: "blob"
  })
}

//post
export const $post = (url, params = {}, contentType = "multipart/form-data", options = {}) => {
  if(contentType == "multipart/form-data") {
    let formData = new FormData
    for(let key in params) {
      formData.append(key, params[key])
    }
    params = formData
  }
  return service.post(url, params, {
    headers: {
      'Content-Type': contentType
    },
    ...options
  })
}