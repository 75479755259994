/**
 * 存储数据
 * 接收三个参数：键、值、有效天数
 */
export const setItem = (key, value, expire = null) => {
  if(expire) {
    let time = 120 * 1000// expire * 24 * 3600 * 1000
    value = {
      data: value,
      time: Date.now(),
      expire: time
    }
  }

  // 将数组、对象类型的数据转化为 JSON 字符串进行存储
  if (typeof value === 'object') {
    value = JSON.stringify(value)
  }

  window.localStorage.setItem(key, value)
}

/**
 * 获取数据
 */
export const getItem = key => {
  const data = window.localStorage.getItem(key)
  if (!data) return data
  try {
    let val = JSON.parse(data)
    // 存值时间戳 +  有效时间 = 过期时间戳
    // 如果当前时间戳大于过期时间戳说明过期了，删除值并返回提示
    if(val.expire) {
      if (Date.now() > val.time + val.expire) {
        localStorage.removeItem(key)
        return '值已失效'
      }
      return val.data
    }
    return val
  } catch (err) {
    return data
  }
}

/**
 * 删除数据
 */
export const removeItem = key => {
  window.localStorage.removeItem(key)
}

/**
 * 删除所有数据
 */
export const removeAllItem = key => {
  window.localStorage.clear()
}
