import Vue from 'vue'
import moment from 'moment'

Vue.filter('YMD', function (value) {
  if (!value) return ''
  return moment(value).format('YYYY-MM-DD')
})

Vue.filter('YMDHMS', function (value) {
  if (!value) return ''
  return moment(value).format('YYYY-MM-DD HH:mm:ss')
})

Vue.filter('limitTo', function(value, length) {
  if (!value) return '--'
  value = value.toString()
  if (value.length > length) {
      return value.substring(0, length) + '...'
  } else {
      return value
  }
})